<template>
  <header class="header" :class="{'expanded': isMenuShown}">
    <div class="container h-100">
      <div class="row gx-0 h-100 justify-content-between align-items-center">
        <div class="col-auto">
          <router-link to="/" @click="expandMenu(false)">
            <img :src="pariLogo" class="logo" alt="Euro 2024" />
          </router-link>
        </div>
        <div class="col-auto d-none d-lg-block">
          <ul class="header-menu">
            <li><router-link to="/#schedule" active-class="active">Расписание и результаты</router-link></li>
            <li><router-link to="/blog" active-class="active">Блог</router-link></li>
          </ul>
        </div>
        <div class="col-auto d-none d-lg-flex" :class="{'col-3': true}" v-if="false">
          <a class="button button--green" href="">Войти</a>
          <a class="button" href="">Регистрация</a>
        </div>
        <div class="col-2 d-none d-lg-flex" v-else></div>
        <div class="col-auto d-lg-none">
          <a class="header-burger" @click="expandMenu(!isMenuShown)"><span></span></a>
        </div>
      </div>
      <div class="header-menu__mobile d-lg-none">
        <div class="wrapper">
          <ul>
            <li><router-link to="/#schedule" @click="expandMenu(false)">Расписание и результаты</router-link></li>
            <li><router-link to="/blog" @click="expandMenu(false)">Блог</router-link></li>
          </ul>
          <template v-if="false">
          <a class="button button--green" href="">Войти</a>
          <br>
          <a class="button" href="">Регистрация</a>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
export default {
  name: "HeaderArea",
  computed: {
    ...mapGetters(['isMenuShown']),
  },
  methods: {
    ...mapActions(['expandMenu']),
  },
  data(){
    return{
      section_schedule: null,
      pariLogo: require('@/assets/img/euro-cast.svg'),
    }
  }
}
</script>

<style scoped>
  .header{
    height: 4.3rem;
    position: relative;
    z-index: 10;
    background: #1E1E27;
  }
  /*.header > .container > .row{*/
  /*  border-bottom: 1px solid rgba(255, 255, 255, 0.20);*/
  /*}*/
  .logo{
    display: block;
    width: 7.5rem;
    height: auto;
  }
  .button{
    margin-left: 1.4rem;
  }
  .button:first-child{
    margin-left: 0;
  }
  .header-menu{
    font-size: 0;
  }
  .header-menu li{
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 700;
    margin-left: 4.1rem;
  }
  /*.header-menu li:first-child{*/
  /*  margin-left: 0;*/
  /*}*/
  .header-menu a{
    transition: color 0.4s ease;
  }
  .header-menu .active,
  .header-menu a:hover{
    color: #00C7B1;
  }
  .header-menu .active{
    cursor: default;
  }
  .header-burger{
    width: 2.4rem;
    height: 1.6rem;
    display: block;
    position: relative;
    cursor: pointer;
  }
  .header-burger:before,
  .header-burger:after,
  .header-burger span{
    position: absolute;
    left: 0;
    transform: translate(0, -50%);
    width: 100%;
    height: 3px;
    background: #fff;
  }
  .header-burger:before{
    content: '';
    top: 0;
  }
  .header-burger:after{
    content: '';
    top: 100%;
  }
  .header-burger span{
    top: 50%;
    transition: opacity 0.4s ease;
  }
  .header-burger:before,
  .header-burger:after{
    transition: all 0.4s ease;
  }
  .header.expanded .header-burger span{
    opacity: 0;
  }
  .header.expanded .header-burger:before{
    top: 50%;
    transform: rotate(45deg);
  }
  .header.expanded .header-burger:after{
    top: 50%;
    transform: rotate(-45deg);
  }
  .header-menu__mobile{
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 4.3rem;
    padding: 1px 16px;
    height: calc(100vh - 4.3rem);
    overflow: hidden;
    background: linear-gradient(180deg, #022D4A -4.3rem, #023A4E 8rem, #0E3637 23.2rem, #0E2828 34.57rem, #000 52.5rem);
    transition: transform 0.5s ease;
    transform: translate(100%, 0%);
  }
  .header.expanded .header-menu__mobile{
    transform: translate(0%, 0%);
  }
  .header-menu__mobile .wrapper{
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .header.expanded .header-menu__mobile .wrapper{
    overflow-y: auto;
  }
  .header-menu__mobile ul{
    padding-top: 30px;
  }
  .header-menu__mobile li{
    /*margin-bottom: 26px;*/
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 700;
  }
  .header-menu__mobile .button{
    margin-bottom: 40px;
    margin-left: 0;
  }
  @media(max-width: 767px){
    .header{
      height: 48px;
    }
    .header-menu__mobile{
      top: 48px;
    }
  }
</style>
