<template>
  <div class="page">
    <div class="page-decor">
      <div class="page-decor">
        <picture>
          <source :srcset="decorWebp" type="image/webp">
          <source :srcset="decorJpg" type="image/jpeg">
          <img :src="decorJpg" alt="">
        </picture>
      </div>
    </div>
    <header-area />
    <div style="display: none">
      <div itemscope itemtype="http://schema.org/Organization">
        <span itemprop="name">ООО «EURO-CAST»</span>
        Адрес и телефон: <div itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
        <span itemprop="streetAddress">Петровка ул., д. 25, этаж 2 помещ. 1</span>
        <span itemprop="postalCode">125009</span>
        <span itemprop="addressLocality">г.Москва, Россия</span>
      </div>
        Телефон:<span itemprop="telephone">8 (800) 770 68 70</span>, E-mail: <span itemprop="email">support@euro-2024cast.ru</span>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
  import HeaderArea from "@/components/HeaderArea";

  export default {
    components: {
      HeaderArea
    },
    data(){
      return{
        decorJpg: require('@/assets/img/bg-football-min.jpg'),
        decorWebp: require('@/assets/img/bg-football.webp'),
      }
    },
    mounted(){
      if (!this.$cookies.isKey('euro2024cast')){
        this.$cookies.set('euro2024cast', true, '30d');
      }
    }
  }
</script>

<style>

</style>
