import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'tv',
    component: () => import(/* webpackChunkName: "tv" */ '../views/tv/TvView.vue')
  },
  {
    path: '/event-:event',
    name: 'event',
    component: () => import(/* webpackChunkName: "event" */ '../views/tv/_event.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/blog/BlogView.vue')
  },
  {
    path: '/blog/:slug',
    name: 'article',
    component: () => import(/* webpackChunkName: "article" */ '../views/blog/_slug.vue')
  },

  // {
  //   path: '/',
  //   name: 'blog',
  //   component: () => import(/* webpackChunkName: "blog" */ '../views/blog/BlogView.vue')
  // },
  // {
  //   path: '/:slug',
  //   name: 'article',
  //   component: () => import(/* webpackChunkName: "article" */ '../views/blog/_slug.vue')
  // },

  {
    path: '/author-:author',
    name: 'author',
    component: () => import(/* webpackChunkName: "author" */ '../views/_author.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    component: () => import(/* webpackChunkName: "error404" */ '../views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { x: 0, y: 0 };
  }
})

export default router
