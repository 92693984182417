import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    metaTags: {
      home: {
        title: 'ЕВРО 2024 трансляция: СМОТРЕТЬ онлайн',
        description: 'Где смотреть ЕВРО 2024: СМОТРИ у нас все матчи онлайн в России бесплатно. Результаты всех матчей. Календарь и Расписание',
        og: {
          "og:type": "https://euro-2024cast.ru/",
          "og:title": "Турнирная таблица ЕВРО‑2024",
          "og:url": "https://euro-2024cast.ru/",
          "og:image": "https://pari-euro-24-backend.we-demonstrate2.ru/images/pari.jpg",
          "og:description": "Актуальная турнирная таблица Чемпионата Европы 2024 (ЕВРО-2024). Смотрите результаты всех матчей. Календарь событий список матчей и статистика команд.",
          "og:site_name": "https://euro-2024cast.ru/ - Турнирная таблица ЕВРО 2024",
          "article:published_time": "2024-04-17T10:00:00+03:00",
          "article:modified_time": "2024-06-05T10:00:00+03:00",
          "referrer": "origin-when-crossorigin"
        },
        twitter: {
          "twitter:card": "summary",
          "twitter:site": "https://euro-2024cast.ru/",
          "twitter:title": "Турнирная таблица ЕВРО‑2024",
          "twitter:description": "Актуальная турнирная таблица Чемпионата Европы 2024 (ЕВРО-2024). Смотрите результаты всех матчей. Календарь событий список матчей и статистика команд.",
          "twitter:creator": "https://euro-2024cast.ru/",
          "twitter:image:src": "https://pari-euro-24-backend.we-demonstrate2.ru/images/pari.jpg",
          "twitter:domain": "https://euro-2024cast.ru/"
        }
      },
      blog: {
        title: 'Новости Чемпионата Европы по футболу 2024: Последние события в мире футбола',
        description: 'Новости ЕВРО 2024 - Всё самое актуальное и свежие новости футбола, результаты матчей, онлайн-трансляции. Следите как проходит чемпионат Европы по футболу 2024 в Германии',
        og: {
          "og:type": "https://euro-2024cast.ru/",
          "og:title": "Турнирная таблица ЕВРО‑2024",
          "og:url": "https://euro-2024cast.ru/blog",
          "og:image": "https://pari-euro-24-backend.we-demonstrate2.ru/images/pari.jpg",
          "og:description": "Актуальная турнирная таблица Чемпионата Европы 2024 (ЕВРО-2024). Смотрите результаты всех матчей. Календарь событий список матчей и статистика команд.",
          "og:site_name": "https://euro-2024cast.ru/ - Турнирная таблица ЕВРО 2024",
          "article:published_time": "2024-04-17T10:00:00+03:00",
          "article:modified_time": "2024-06-05T10:00:00+03:00",
          "referrer": "origin-when-crossorigin"
        },
        twitter: {
          "twitter:card": "summary",
          "twitter:site": "https://euro-2024cast.ru/",
          "twitter:title": "Блог",
          "twitter:description": "Новости ЕВРО 2024 - Всё самое актуальное и свежие новости футбола, результаты матчей, онлайн-трансляции. Следите как проходит чемпионат Европы по футболу 2024 в Германии",
          "twitter:creator": "https://euro-2024cast.ru/",
          "twitter:image:src": "https://pari-euro-24-backend.we-demonstrate2.ru/images/pari.jpg",
          "twitter:domain": "https://euro-2024cast.ru/"
        }
      },
      tv: {
        title: 'Турнирная таблица ЕВРО-2024 - Группы, результаты матчей, статистика команд',
        description: 'Актуальная турнирная таблица Чемпионата Европы 2024 (ЕВРО-2024). Смотрите результаты всех матчей. Календарь событий список матчей и статистика команд.'
      },
    },
    mobMenuShown: false,
    matches: [],
    groups: [],
    groupsDetails: {},
    champions: {
      'OG': {
        name: 'OG',
        avatar: require('@/assets/img/teams/og.png'),
      },
      'Tundra Esports': {
        name: 'Tundra Esports',
        avatar: require('@/assets/img/teams/tundra-esports.png'),
      },
      'Team Liquid': {
        name: 'Team Liquid',
        avatar: require('@/assets/img/teams/team-liquid.png'),
      },
      'Team Spirit': {
        name: 'Team Spirit',
        avatar: require('@/assets/img/teams/team-spirit.png'),
      },
      'Wings Gaming': {
        name: 'Wings Gaming',
        avatar: require('@/assets/img/teams/wings-gaming.png'),
      }
    },
    startTeams: {
      'default': {
        name: 'default',
        avatar: require('@/assets/img/default.svg'),
      },
      'Team Liquid': {
        name: 'Team Liquid',
        avatar: require('@/assets/img/teams/team-liquid.png'),
        structure: [
          {
            name: 'miCKe',
          },
          {
            name: 'Nisha',
          },
          {
            name: 'zai',
          },
          {
            name: 'Boxi',
          },
          {
            name: 'Insania',
          }
        ]
      },
      'Gaimin Gladiators':{
        name: 'Gaimin Gladiators',
        avatar: require('@/assets/img/teams/gaimin-gladiators.png'),
        structure: [
          {
            name: 'dyrachyo',
          },
          {
            name: 'Quinn',
          },
          {
            name: 'Ace',
          },
          {
            name: 'tOfu',
          },
          {
            name: 'Seleri',
          }
        ]
      },
      'Tundra Esports': {
        name: 'Tundra Esports',
        avatar: require('@/assets/img/teams/tundra-esports.png'),
        structure: [
          {
            name: 'skiter',
          },
          {
            name: 'Nine',
          },
          {
            name: '33',
          },
          {
            name: 'Topson',
          },
          {
            name: 'Sneyking',
          }
        ]
      },
      '9Pandas': {
        name: '9Pandas',
        avatar: 'https://www.cybertourney.ru/img/teams/328/logos/md/190px-9_Pandas_Logo_2023-07-03.png',
        structure: [
          {
            name: 'RAMZES666',
          },
          {
            name: 'kiyotaka',
          },
          {
            name: 'MieRo`',
          },
          {
            name: 'Antares',
          },
          {
            name: 'Solo',
          }
        ]
      },
      'Evil Geniuses': {
        name: 'Evil Geniuses',
        avatar: require('@/assets/img/teams/evil-geniuses.png'),
        structure: [
          {
            name: 'Pakazs',
          },
          {
            name: 'Chris Luck',
          },
          {
            name: 'Wisper',
          },
          {
            name: 'Matthew',
          },
          {
            name: 'Panda',
          }
        ]
      },
      'LGD Gaming': {
        name: 'LGD Gaming',
        avatar: 'https://www.cybertourney.ru/img/teams/6/logos/md/190px-LGD_Gaming_Logo_2023-09-04.png',
        structure: [
          {
            name: 'shiro',
          },
          {
            name: 'NothingToSay',
          },
          {
            name: 'niu',
          },
          {
            name: 'planet',
          },
          {
            name: 'y`',
          }
        ]
      },
      'Shopify Rebellion': {
        name: 'Shopify Rebellion',
        avatar: require('@/assets/img/teams/shopify.png'),
        structure: [
          {
            name: 'Arteezy',
          },
          {
            name: 'Abed',
          },
          {
            name: 'SabeRLight-',
          },
          {
            name: 'Cr1t-',
          },
          {
            name: 'Fly',
          }
        ]
      },
      'Talon Esports': {
        name: 'Talon Esports',
        avatar: require('@/assets/img/teams/talon.png'),
        structure: [
          {
            name: '23savage',
          },
          {
            name: 'Mikoto',
          },
          {
            name: 'Jabz',
          },
          {
            name: 'Q',
          },
          {
            name: 'Oli~',
          }
        ]
      },
      'beastcoast': {
        name: 'beastcoast',
        avatar: require('@/assets/img/teams/beastcoast.png'),
        structure: [
          {
            name: 'Parker',
          },
          {
            name: 'DarkMago',
          },
          {
            name: 'Sacred',
          },
          {
            name: 'Scofield',
          },
          {
            name: 'Stinger',
          }
        ]
      },
      'Team Spirit': {
        name: 'Team Spirit',
        avatar: require('@/assets/img/teams/team-spirit.png'),
        structure: [
          {
            name: 'Yatoro',
          },
          {
            name: 'Larl',
          },
          {
            name: 'Collapse',
          },
          {
            name: 'Mira',
          },
          {
            name: 'Miposhka',
          }
        ]
      },
      'TSM': {
        name: 'TSM',
        avatar: require('@/assets/img/teams/tsm.png'),
        structure: [
          {
            name: 'Timado',
          },
          {
            name: 'Bryle',
          },
          {
            name: 'kasane',
          },
          {
            name: 'Ari',
          },
          {
            name: 'Whitemon',
          }
        ]
      },
      'BetBoom Team': {
        name: 'BetBoom Team',
        avatar: 'https://www.cybertourney.ru/img/teams/29/logos/md/190px-BetBoom_Team_Logo_2023-04-18.png',
        structure: [
          {
            name: 'Nightfall',
          },
          {
            name: 'gpk~',
          },
          {
            name: 'Pure~',
          },
          {
            name: 'Save-',
          },
          {
            name: 'TORONTOTOKYO',
          }
        ]
      },
      'Entity': {
        name: 'Entity',
        avatar: require('@/assets/img/teams/entity.png'),
        structure: [
          {
            name: 'watson',
          },
          {
            name: 'Stormstormer',
          },
          {
            name: 'Gabbi',
          },
          {
            name: 'Kataomi`',
          },
          {
            name: 'Fishman',
          }
        ]
      },
      'Quest Esports': {
        name: 'Quest Esports',
        avatar: require('@/assets/img/teams/quest-esports.png'),
        structure: [
          {
            name: 'TA2000',
          },
          {
            name: 'No!ob',
          },
          {
            name: 'Tobi',
          },
          {
            name: 'OmaR`',
          },
          {
            name: 'kaori',
          }
        ]
      },
      'Virtus.pro': {
        name: 'Virtus.pro',
        avatar: 'https://www.cybertourney.ru/img/teams/1/logos/md/190px-Virtus.pro_Logo_2022-04-23.png',
        structure: [
          {
            name: 'Kiritych~',
          },
          {
            name: 'squad1x',
          },
          {
            name: 'Noticed',
          },
          {
            name: 'sayuw',
          },
          {
            name: 'Fng',
          }
        ]
      },
      'Azure Ray': {
        name: 'Azure Ray',
        avatar: 'https://www.cybertourney.ru/img/teams/330/logos/md/190px-Azure_Ray_Logo_2023-07-03.png',
        structure: [
          {
            name: 'Lou',
          },
          {
            name: 'Somnus',
          },
          {
            name: 'chalice',
          },
          {
            name: 'fy',
          },
          {
            name: '天命',
          }
        ]
      },
      'Team SMG': {
        name: 'Team SMG',
        avatar: 'https://www.cybertourney.ru/img/teams/48/logos/md/190px-Team_SMG_Logo_2023-01-16.png',
        structure: [
          {
            name: 'MidOne',
          },
          {
            name: 'No[o]ne-',
          },
          {
            name: 'MinD_ContRoL',
          },
          {
            name: 'ah fu',
          },
          {
            name: 'Jaunuel',
          }
        ]
      },
      'nouns': {
        name: 'nouns',
        avatar: 'https://www.cybertourney.ru/img/teams/51/logos/md/190px-nouns_Logo_2022-06-12.png',
        structure: [
          {
            name: 'K1',
          },
          {
            name: 'Gunnar',
          },
          {
            name: 'Moo',
          },
          {
            name: 'Lelis',
          },
          {
            name: 'Yamsun',
          }
        ]
      },
      'Keyd Stars': {
        name: 'Keyd Stars',
        avatar: 'https://www.cybertourney.ru/img/teams/256/logos/md/190px-Keyd_Stars_Logo_2023-01-02.png',
        structure: [
          {
            name: 'Costabile',
          },
          {
            name: '4nalog',
          },
          {
            name: 'fcr',
          },
          {
            name: 'Kingrd',
          },
          {
            name: 'KJ',
          }
        ]
      },
      'Thunder Awaken': {
        name: 'Thunder Awaken',
        avatar: require('@/assets/img/teams/thunder-awaken.png'),
        structure: [
          {
            name: 'Knight~',
          },
          {
            name: 'SLATEM$',
          },
          {
            name: 'ILICH-',
          },
          {
            name: 'n1ght',
          },
          {
            name: 'Mjz',
          }
        ]
      }
    },
    externals: {}
  },
  getters: {
    getMeta(state){
      return state.metaTags;
    },
    getExternals(state){
      return state.externals;
    },
    getGroupsDetails(state){
      return state.groupsDetails;
    },
    getMatches(state){
      return state.matches;
    },
    getGroups(state){
      return state.groups;
    },
    getStartTeams(state){
      return state.startTeams;
    },
    getChampions(state){
      return state.champions;
    },
    isMenuShown(state){
      return state.mobMenuShown;
    }
  },
  mutations: {
    updateMenuStatus(state, status){
      state.mobMenuShown = status;
    },
    updateMatches(state, matchesArray){
      state.matches = matchesArray;
    },
    updateGroups(state, groupsTables){
      state.groups = groupsTables;
    },
    updateGroupsDetails(state, details){
      state.groupsDetails = details;
    },
    updateExternals(state, obj){
      state.externals = obj;
    }
  },
  actions: {
    getExternalsFromAPI({commit}){
      return axios
          .get('api/info/banners')
          .then(response => {
            commit('updateExternals', response.data);
            return response.data;
          })
          .catch(error => {
            console.log(error);
            return error
          })
    },
    getMatchesFromAPI({commit}){
      return axios
          .get('api/info/coefs')
          .then(response => {
            commit('updateMatches', response.data);
            return response.data;
          })
          .catch(error => {
            console.log(error);
            return error
          })
    },
    getGroupsFromAPI({commit}){
      return axios
          .get('api/euro/groupStage/2020')
          .then(response => {
            commit('updateGroups', response.data);
            return response.data;
          })
          .catch(error => {
            console.log(error);
            return error
          })
    },
    expandMenu(ctx, status = true){
      ctx.commit('updateMenuStatus', status);
      if (status) {
        document.body.classList.add('menu-expanded');
      } else {
        document.body.classList.remove('menu-expanded');
      }
    }
  },
  modules: {
  }
})
