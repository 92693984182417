import { createApp } from 'vue';
import './assets/css/reset.css';
import './assets/css/bootstrap-grid.css';
import './assets/css/style.css';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAwesomePaginate from "vue-awesome-paginate";
import VueCookies from 'vue-cookies';
import VueCountdown from '@chenfengyuan/vue-countdown';
/*
router.beforeEach((to, from, next) => {
    // Отправляем событие "page_view" при каждом переходе на новую страницу
    window.dataLayer.push({
        'event': 'page_view',
        'page_path': to.path
    })
    next()
});
*/

let currentUrl = window.location.href;
let currentDomain = '';

let isMaster = currentUrl.includes('euro-2024cast.ru');

if (isMaster){
    axios.defaults.baseURL = 'https://backend-euro-24.pari.ru/'; // боевой адрес
    currentDomain = 'euro-2024cast.ru'; // TODO заменить
} else {
    axios.defaults.baseURL = 'https://pari-euro-24-backend.we-demonstrate2.ru/'; // тестовый адрес
    currentDomain = '.we-demonstrate2.ru';
}

let myMixin = {
    data() {
        return {
            externalDomain: ''
        }
    },
    methods: {
        getCoefficient: function (arr, val){
            if (arr?.length){
                let elem = arr.filter(el => el.f === val);
                if (elem.length){
                    return elem[0].v.toFixed(2);
                }
            }
            return '–';
        },
    },
    created() {
        if (isMaster){
            this.externalDomain = 'https://euro-24.pari.ru'
        } else {
            this.externalDomain = 'https://pari-euro-24.we-demonstrate2.ru'
        }
    },
}

const vueApp = createApp(App);

vueApp
    .use(store)
    .use(router)
    .use(VueAwesomePaginate)
    .mixin(myMixin)
    .use(VueCookies, { expires: '30d', path: '/', domain: currentDomain, secure: '', sameSite: '' });
vueApp.config.globalProperties.$axios = axios;
vueApp.component(VueCountdown.name, VueCountdown);
vueApp.mount('#app');
